<template>
  <basic-modal :modal-id="'delete-modal'" :size="'md'">
    <template #title>
      {{ $t('general.buttons.confirmDelete') }}
    </template>
    <div class="body">
      <LoadingAnimation class="w-100" v-if="loading" />
      <div class="row">
        <b-col cols="2">
          <svg width="47" height="44" viewBox="0 0 47 44" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fill-rule="evenodd">
              <path
                d="M22.152 3.102a5 5 0 0 1 5.706 2.364L44.14 34.412a4.984 4.984 0 0 1 .457 3.8 5 5 0 0 1-4.815 3.652H7.218c-1.381 0-2.631-.56-3.536-1.465a5 5 0 0 1-.822-5.987L19.142 5.466a4.984 4.984 0 0 1 3.01-2.364z"
                stroke="#0CBA4A" stroke-width="2.727" stroke-linejoin="round" />
              <text font-family="Roboto-Bold, Roboto" font-size="21.818" font-weight="bold" fill="#0CBA4A"
                transform="translate(1 .5)">
                <tspan x="19.664" y="33.636">!</tspan>
              </text>
            </g>
          </svg>
        </b-col>
        <b-col>
          <slot name="delete-message"></slot>
        </b-col>
      </div>

    </div>
    <template #footer>
      <b-button class="w-m" @click="handleCancel" :disabled="loading" :primary="false">{{ $t('general.buttons.cancel')
        }}</b-button>
      <b-button class="w-m" @click="handleDelete" :disabled="loading" :primary="true" variant="success">
        {{ $t('general.buttons.delete') }}
      </b-button>
    </template>



  </basic-modal>
</template>
<script>
import BasicModal from '@/components/modals/BasicModal.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'


export default {
  name: 'SubmitInventoryModal',
  data() {
    return {
      loading: false
    }
  },
  components: {
    BasicModal,
    LoadingAnimation

  },
  props: {

  },
  created() {

  },
  computed: {
  },
  methods: {
    handleDelete() {
      this.$emit('confirmDelete')
    },
    handleCancel() {
      this.$emit('cancelDelete')
    }
  }
}
</script>
<style scoped></style>
